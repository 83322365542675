import { getResourcesByLocale } from '@/configs/locale-runtime.config';

import localeMessages from '../fallback/ja';

export default defineI18nLocale(async (locale: string) => {
  try {
    // Get resources by locale merge from CDN + seed-data + default locale
    const result = await getResourcesByLocale(locale);
    return result;
  } catch (error) {
    /**
     * When an error occurs while fetching the resource
     * it will callback to use the file locally represented in the 'i18n/fallback' folder, which was generated at build-time.
     */
    return {
      ...localeMessages
    };
  }
});
